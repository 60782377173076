var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"head_box"},[_vm._m(0),_c('div',{staticClass:"head_content"},[_c('div',{staticClass:"flex head_content_box"},[_c('div',{staticClass:"flex-center left"},[_c('div',[_vm._m(1),_vm._m(2),_c('div',{staticClass:"left_item"},[_vm._m(3),_c('a',{attrs:{"href":_vm.linkUrl}},[_c('div',{staticClass:"w-262px left_content",on:{"click":function($event){return _vm.handlClick(1)}}},[_vm._v(" 浙江省杭州市西湖区西斗门路3号天堂软件园A幢1楼1160室（自主申报） ")])])]),_c('div',{staticClass:"left_item",staticStyle:{"margin-top":"40px"}},[_vm._m(4),_c('a',{attrs:{"href":_vm.linkUrl}},[_c('div',{staticClass:"w-262px left_content",on:{"click":function($event){return _vm.handlClick(1)}}},[_vm._v(" 86-18138149921 ")])])])])]),_c('div',{staticClass:"right"},[_c('a',{attrs:{"href":_vm.linkUrl}},[_c('img',{attrs:{"src":require("../assets/images/map_pic.png"),"alt":""}})])])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("../assets/images/contact_head.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item left_item1"},[_c('div',{staticClass:"flex-items-center left_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/icon_1.png"),"alt":""}}),_vm._v(" 商务合作 ")]),_c('div',{staticClass:"left_content"},[_vm._v("bd@01bite.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item left_item1"},[_c('div',{staticClass:"flex-items-center left_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/icon_2.png"),"alt":""}}),_vm._v(" 招聘相关 ")]),_c('div',{staticClass:"left_content"},[_vm._v("hr@01bite.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-items-center left_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/icon_3.png"),"alt":""}}),_vm._v(" 公司地址 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-items-center left_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/icon_4.png"),"alt":""}}),_vm._v(" 联系方式 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"partners_container"},[_c('div',{staticClass:"title"},[_vm._v("合作伙伴")]),_c('div',{staticClass:"mb-50px flex-center partners_box"},[_c('img',{attrs:{"src":require("../assets/images/contact_1.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/contact_2.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/contact_3.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/contact_4.png"),"alt":""}})]),_c('div',{staticClass:"flex-center partners_box"},[_c('img',{attrs:{"src":require("../assets/images/contact_5.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/contact_6.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/contact_7.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/contact_8.png"),"alt":""}})])])
}]

export { render, staticRenderFns }